/* eslint-disable */
import React, { useEffect, useRef } from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'
import Breadcrumbs from 'components/layout/Breadcrumbs'

import Header from 'components/layout/Headers/Secondary'
import HeaderFilters from 'components/layout/Headers/Homepage/Filters'
import Browser from 'components/layout/Flat/Browser'

import useFilters from 'hooks/useFilters'

// @ts-ignore
import type { FindApartmentPageQuery } from 'types/graphql'
import type { Flat } from 'types/flat'

import {
  FavouritesColumn,
  InvestmentColumn,
  NumberColumn,
  TypeColumn,
  AreaColumn,
  FloorColumn,
  RoomsColumn,
  PriceColumn,
  PricePerM2Column,
  StatusColumn,
} from 'constants/columns'

const FindApartmentPage: React.FC<PageProps<FindApartmentPageQuery>> = ({
  data,
  location,
}) => {
  const PAGE = data?.page?.pageOfferApartments
  const PAGE_SEO = data?.page?.seo
  const FLATS = data.flats.nodes as Flat[]

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const [filteredFlats, { fields, filters, applyFilters, setFilters }] =
    useFilters('flat', { items: FLATS })

  const ref = useRef(true)

  const applyFiltersFromHomepage = () => {
    // @ts-ignore
    setFilters(location.state.filters)
    applyFilters()
    if (ref.current) {
      ref.current = false
    }
  }

  useEffect(() => {
    // @ts-ignore
    if (location.state?.filters) {
      applyFiltersFromHomepage()
    }
  }, [ref.current])

  return (
    <Layout background="white50">
      <SEO seo={PAGE_SEO} />
      <Breadcrumbs crumbs={[{ name: 'Znajdź mieszkanie' }]} />
      <Header
        title={PAGE.offerApartmentsPageS1Title!}
        image={{
          src: PAGE.offerApartmentsPageS1Img?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE.offerApartmentsPageS1Img?.altText!,
        }}
      >
        <HeaderFilters
          includedFilters={fields}
          filters={filters}
          applyFilters={applyFilters}
          setFilters={setFilters}
        />
      </Header>
      <main>
        <Browser
          flats={filteredFlats}
          columns={[
            NumberColumn,
            TypeColumn,
            InvestmentColumn,
            AreaColumn,
            FloorColumn,
            RoomsColumn,
            PriceColumn,
            PricePerM2Column,
            StatusColumn,
            FavouritesColumn,
          ]}
        />
      </main>
    </Layout>
  )
}

export default FindApartmentPage

export const query = graphql`
  query FindApartmentPage($locale: String) {
    flats: allFlats {
      nodes {
        ...FlatsFragment
      }
    }
    page: wpPage(
      language: { slug: { eq: $locale } }
      slug: { regex: "/znajdz-mieszkanie/" }
    ) {
      seo {
        ...WpSEO
      }
      pageOfferApartments {
        offerApartmentsPageS1Title
        offerApartmentsPageS1Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1100
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`
